<template>
  <div id="app">
    <LoadingScreen :isLoading="isLoading" v-if="isLoading"/>
    <MenuBar class="menu-bar"/>
    <main>
      <div class="main-content">
        <router-view/>
      </div>
    </main>
    <footer>
      <div>
        <img
            src="https://fiveamtech.it/logo.png"
            alt="FiveamTech"
            @click="navigateTo('https://fiveamtech.it')"
        >
        <p>
          Powered by FiveamTech
        </p>
      </div>
      <div>
        <img
            src="./assets/logo-60x60.png"
            alt="Cinopedia"
            @click="navigateTo('/')"
        >
        <p>
          © 2024 Cinopedia.cloud All rights reserved.
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import LoadingScreen from "@/components/LoadingScreen.vue";

const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = (window).ResizeObserver;
(window).ResizeObserver = class ResizeObserver extends _ {
  constructor(callback) {
    callback = debounce (callback, 20);
    super(callback);
  }
};
import MenuBar from "@/components/MenuBar.vue";

export default {
  name: 'App',
  components: {LoadingScreen, MenuBar},
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    }
  },
  methods: {
    navigateTo(url) {
      window.location.href = url
    }
  }
}
</script>

<style lang="scss">
@import './style/_variables.scss';

#app {
  overflow-y: hidden;
}
body {
  overflow-y: auto;
  background-color: $background-color;
  width: 100vw;
  height: 100vh;
  font-family: $font-base;
}
.main-content {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  max-width: 1000px;
}
main {
  min-height: $main-min-height;
}
footer {
  min-height: $footer-height;
  background-color: $footer-background-color;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
  align-items: center;
  div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
      img {
        filter: none;
        transform: scale(1.1);
      }
      p {
        color: $primary-color;
        transform: scale(1.1);
      }
    }
    
    p {
      transition: transform 0.3s ease;
      color: $footer-text-color;
      font-family: Roboto, sans-serif;
      font-size: 0.8rem;
    }
    img {
      transition: transform 0.3s ease;
      filter: grayscale(100%);
      opacity: 0.8;
      width: 60px;
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}



div.v-list-item-title {
  white-space: normal;
  text-overflow: unset;
  text-transform: uppercase;
}
button.v-expansion-panel-title--active {
  background-color: $primary-color;
  color: $primary-contrast-color;
}
</style>