<template>
  <div class="home-container">
    <img
        src="../assets/logo.png"
        alt="Cinopedia"
        class="home-image"
    >
    <h1>CINOPEDIA.CLOUD</h1>
    <p>{{getTranslation('it', "welcome-text")}} <a href="mailto:cinopedia@fiveamtech.it">cinopedia@fiveamtech.it</a></p>
    <div class="background-container primary-box">
      <h3>{{getTranslation('it', "welcome-text-search")}}</h3>
      <div class="search-container">
        <SearchAllAutocomplete
            class="search-autocomplete"
            :label="getTranslation('it', 'search')"
        />
      </div>
    </div>
    
  </div>
</template>

<script>
import SearchAllAutocomplete from "@/components/SearchAllAutocomplete.vue";
import {getTranslation} from "@/services/stringTableService";

export default {
  name: 'HomeHome',
  methods: {getTranslation},
  components: {SearchAllAutocomplete},
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../style/_variables.scss';

.home-container {
  //background-color: rgba($primary-color,0.1);
  //-webkit-box-shadow: 0px 0px 5px 5px rgba($primary-color,0.64);
  //box-shadow: 0px 0px 5px 5px rgba($primary-color,0.64);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    margin-top: 5px;
    margin-bottom: 20px;
    font-family: $font-base;
    font-weight: 500;
    a {
      color: $primary-color;
      font-weight: 700;
    }
  }
  h1 {
    color:transparent;
    background-clip: text;
    background-image: $primary-gradient;
    font-family: $header-font;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-size: 3rem;
  }
  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: $header-font;
  }
  .home-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }
  .background-container {
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    .search-container {      
      width: 100%;
      margin-top: 20px;
    }
    
  }

}

@media (max-width: 700px) {
  .home-container {
    width: 100%;
    min-height: $main-min-height;
    margin: 0;
    border-radius: 0;
  }
}
</style>
