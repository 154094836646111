import axios from 'axios';
import Breed from "@/entities/Breed";
import EnciGroup from "@/entities/EnciGroup";
import MotivationalGroup from "@/entities/MotivationalGroup";
class ApiService {
    constructor(baseUrl, useCache = false) {
        this.baseUrl = baseUrl;
        this.useCache = useCache;
    }
    
    async logAccess(id, additionalData) {
        await axios.post(`${this.baseUrl}/access/${id}`, additionalData ?? {});
    }

    async getBreed(id) {
        if(this.useCache) {
            const cache = await fetch(`/cache/RZ-${id}.json`);
            if(cache.ok) {
                return new Breed(await cache.json());
            }
        }
        
        const response = await axios.get(`${this.baseUrl}/breed/${id}`);
        return new Breed(response.data);
    }    
    async getEnciGroup(id) {
        if(this.useCache) {
            const cache = await fetch(`/cache/ENCI-${id}.json`);
            if(cache.ok) {
                const res = await cache.json();
                return new EnciGroup(res);
            }
        }
        const response = await axios.get(`${this.baseUrl}/enci-groups/${id}`);
        const enciGroup = new EnciGroup(response.data);
        enciGroup.breeds = await this.getEnciGroupBreeds(id);
        return new EnciGroup(response.data);
    }
    
    async getMotivationalGroup(id) {
        if(this.useCache) {
            const cache = await fetch(`/cache/GM-${id}.json`);
            if(cache.ok) {
                const res = await cache.json();
                return new MotivationalGroup(res);
            }
        }
        const response = await axios.get(`${this.baseUrl}/motivation-group/${id}`);
        return new MotivationalGroup(response.data);
    }
    async getEnciGroupBreeds(id) {
        const response = await axios.get(`${this.baseUrl}/breed?EnciGroupId=${id}`);
        return response.data.map(breed => new Breed(breed));
    }
}

const apiService = new ApiService('https://cinopedia.cloud/api/latest', true);
export default apiService;