<template>
  <v-toolbar class="menu-bar" >
    <img 
        src="../assets/logo-60x60.png" 
        alt="Cinopedia" 
        height="60" 
        width="60"
        @click="navigateTo('/')"
        style="cursor:pointer;"
    >
    <v-spacer></v-spacer>
    <SearchAllAutocomplete
        ref="searchComponent"
        v-if="showSearch" 
        class="search" 
        variant="solo" 
        :label="getTranslation('search')"
    />
<!--    <v-btn icon @click="toggleSearch">-->
<!--      <v-icon>mdi-magnify</v-icon>-->
<!--    </v-btn>-->
<!--    <v-menu transition="scale-transition">-->
<!--      <template v-slot:activator="{ props }">-->
<!--        <v-btn icon v-bind="props">-->
<!--          <v-icon>mdi-dots-vertical</v-icon>-->
<!--        </v-btn>-->
<!--      </template>-->
<!--      <v-list>-->
<!--        <v-list-item @click="navigateTo('motivational-groups')">-->
<!--          <v-list-item-title>{{getTranslation("motivational-groups")}}</v-list-item-title>-->
<!--        </v-list-item>-->
<!--        <v-list-item @click="navigateTo('enci-groups')">-->
<!--          <v-list-item-title>{{getTranslation("enci-groups")}}</v-list-item-title>-->
<!--        </v-list-item>-->
<!--        <v-list-item @click="navigateTo('breed-list')">-->
<!--          <v-list-item-title>{{getTranslation("breed-list")}}</v-list-item-title>-->
<!--        </v-list-item>-->
<!--      </v-list>-->
<!--    </v-menu>-->
  </v-toolbar>
</template>

<script>
import {getTranslation} from "@/services/stringTableService";
import SearchAllAutocomplete from "@/components/SearchAllAutocomplete.vue";

export default {
  name: 'MenuBar',
  components: {SearchAllAutocomplete},
  data() {
    return {
      showSearch: true
    }
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
    getTranslation(key) {
      return getTranslation("it", key)
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (this.showSearch) {
        this.$nextTick(() => {
          this.$refs.searchComponent.focusInput();
        });
      } 
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../style/_variables.scss';
.menu-bar {
  background: $primary-gradient;
  color: $primary-contrast-color;
  .search {
    width: 100%;
    min-width: 180px;
    max-width: 300px;
  }
}
</style>