import apiService from "@/services/ApiService";

class SidService {
    constructor() {
        this.sid = window.localStorage.getItem('sid')
        if(!this.sid) {
            this.sid = Math.random().toString(36).substring(2, 15) +
                Date.now().toString() +
                Math.random().toString(36).substring(2, 15)
            window.localStorage.setItem('sid', this.sid)
        }
    }
    getSid() {
        return window.localStorage.getItem('sid')
    }
    
    logAccess(additionalData) {
        apiService.logAccess(this.sid, additionalData)
            .then(() => console.log('Access logged'))
            .catch(() => console.error('Error logging access'));
    }
}

const sidService = new SidService();
export default sidService;



