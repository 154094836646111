<template>
  <div class="clicker-page">
    <div class="title-box primary-box">
      <h1>Clicker</h1>
    </div>
    <div>
      <button @click="playSound">
        <ClickerIcon class="clicker-icon"/>
      </button>
    </div>
  </div>
</template>

<script>
import ClickerIcon from './icons/ClickerIcon.vue';
import clicker from '../assets/clicker.mp3';

export default {
  components: {
    ClickerIcon
  },
  methods: {
    playSound() {
      let audio = new Audio(clicker);
      audio.play();
    }
  }
}
</script>
<style lang="scss" scoped>
.clicker-page {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .clicker-icon {
    width: 400px;
    margin: 20px 0;
  }
}
</style>