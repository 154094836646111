<template>
  <div class="breed-detail">
    <img :src="breed.imageUrl" alt="Breed image" class="breed-image">
    <div class="title-box primary-box">
      <h2>{{ breed.name?.get(language) ?? "" }}</h2>
      <h2 class="breed-group" @click="goToEnciGroup()">
        {{getTranslation(language, "group").toUpperCase()}} {{ breed.externalId?.split('-')[0].padStart(2, '0') ?? "" }}
      </h2>
      <h2 class="breed-group" @click="goToEnciGroup()">{{ breed.enciSection?.get(language) ?? "" }}</h2>
    </div>
    <v-expansion-panels variant="popout" class="my-4">
      <v-expansion-panel 
          :title="
          breed?.motivationalGroup?.name?.get(language) 
            ? (getTranslation(language, 'motivational-group') + ' - ' + breed?.motivationalGroup?.name?.get(language))
            : getTranslation(language, 'motivational-group')"
      >
        <v-expansion-panel-text v-if="breed.motivationalGroup">
          <MotivationalGroupItem :motivationalGroup="breed.motivationalGroup" :language="language"/>
          <h5>{{getTranslation(language, "motivational-group-warning")}}</h5>
        </v-expansion-panel-text>
        <v-expansion-panel-text v-else>
          <h2>{{getTranslation(language, "to-be-defined")}}</h2>
          <p>{{getTranslation(language, "if-you-have-it")}} <a href="mailto:cinopedia@fiveamtech.it">cinopedia@fiveamtech.it</a></p>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel :title="getTranslation(language, 'history-and-description')">
        <v-expansion-panel-text>
          <h2>{{getTranslation(language, "names").toUpperCase()}}</h2>
          <div class="breed-names">
            <p v-if="breed.name.get('IT')"><b>IT</b> - {{breed.name.get('IT')}}</p>
            <p v-if="breed.name.get('EN')"><b>EN</b> - {{breed.name.get('EN')}}</p>
            <p v-if="breed.name.get('FR')"><b>FR</b> - {{breed.name.get('FR')}}</p>
            <p v-if="breed.name.get('DE')"><b>DE</b> - {{breed.name.get('DE')}}</p>
            <p v-if="breed.name.get('ES')"><b>ES</b> - {{breed.name.get('ES')}}</p>
          </div>
          <v-divider class="divider"></v-divider>
          <h2>{{getTranslation(language, "description").toUpperCase()}}</h2>
          <p>{{breed.description.get(language)}}</p>
          <v-divider class="divider"></v-divider>
          <h2>{{getTranslation(language, "history").toUpperCase()}}</h2>
          <p>{{breed.history.get(language)}}</p>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel :title="getTranslation(language, 'breed-standard-and-sources')">
        <v-expansion-panel-text>
          <div class="breed-standard-container">
            <h2>{{getTranslation(language, 'source-link')}}</h2>
            <div class="breed-standard-container__link">
              <div v-if="breed.enciLink">
                <a :href="breed.enciLink" target="_blank">ENCI</a>
              </div>
              <div v-if="breed.fciLink">
                <a :href="breed.fciLink" target="_blank">FCI</a>
              </div>
            </div>
            <h2>{{getTranslation(language, 'pdf-standard')}}</h2>
            <div class="breed-standard-container__pdf">
              <a :href="breed.standardPdfLink?.get('IT')" v-if="breed.standardPdfLink?.get('IT')" target="_blank">PDF-IT</a>
              <a :href="breed.standardPdfLink?.get('EN')" v-if="breed.standardPdfLink?.get('EN')" target="_blank">PDF-EN</a>
              <a :href="breed.standardPdfLink?.get('FR')" v-if="breed.standardPdfLink?.get('FR')" target="_blank">PDF-FR</a>
              <a :href="breed.standardPdfLink?.get('DE')" v-if="breed.standardPdfLink?.get('DE')" target="_blank">PDF-DE</a>
              <a :href="breed.standardPdfLink?.get('ES')" v-if="breed.standardPdfLink?.get('ES')" target="_blank">PDF-ES</a>
            </div>
          </div>
        </v-expansion-panel-text>
        
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import apiService from "@/services/ApiService";
import {getTranslation} from "@/services/stringTableService";
import MotivationalGroupItem from "@/components/MotivationalGroupItem.vue";

export default {
  name: 'BreedDetail',
  components: {MotivationalGroupItem},
  props: {
    id: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      breed: {},
      language: "IT"
    }
  },
  created() {
    this.fetchBreed();
  },
  methods: {
    getTranslation,
    async fetchBreed() {
      try {
        this.$store.dispatch('setLoading', true);
        const breed = await apiService.getBreed(this.id);
        this.breed = breed;
        console.log(breed);
        this.updateUrlWithSlug(breed);
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch('setLoading', false);
      }
    },
    goToEnciGroup() {
      this.$router.push({ name: 'EnciGroupDetail', params: { id: this.breed.enciGroupId } });
    },
    updateUrlWithSlug(data) {
      this.$router.replace({
        name: 'BreedDetail',
        params: { id: data.id, slug: data.externalId },
        
      });
    }
  },
  watch: {
    $route(to, from) {
      if(to.params.id !== from.params.id) 
        this.fetchBreed();
    }
  }
}
</script>

<style scoped lang="scss">
@import "../style/_variables.scss";

.breed-detail {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
  .breed-image {
    width: 50%;
    margin: 20px 0;
  }
  .breed-group {
    text-decoration: underline;
    cursor: pointer;
  }
  
  .divider {
    margin: 20px 0;
  }
  
  h1 {
    font-family: $header-font;
    font-size: 3rem;
    font-weight: bolder;
  }
  h2 {
    font-family: $header-font;
    font-weight: bolder;
  }
  
  .breed-standard-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    a {
      color: $primary-color;
      text-decoration: underline;
      cursor: pointer;
    }
    .breed-standard-container__link {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 10px;
    }
    .breed-standard-container__pdf {
      flex-wrap: wrap;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 10px;
    }
  }
}
</style>