import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
import BreedDetail from "../components/BreedDetail.vue";
import EnciGroupDetail from "@/components/EnciGroupDetail.vue";
import MotivationalGroupDetail from "@/components/MotivationalGroupDetail.vue";
import sidService from "@/services/sidService";
import ClickerPage from "@/components/ClickerPage.vue";

const routes = [
    { 
        path: '/', 
        component: Home,
        name: 'Home'
    },
    {
        path: '/breed/:id/:slug?',
        name: 'BreedDetail',
        component: BreedDetail,
        props: true
    },
    {
        path: '/enci-group/:id/:slug?',
        name: 'EnciGroupDetail',
        component: EnciGroupDetail,
        props: true
    },
    {
        path: '/motivational-classification/:id/:slug?',
        name: 'MotivationalGroupDetail',
        component: MotivationalGroupDetail,
        props: true
    },
    {
        path: '/clicker',
        name: 'Clicker',
        component: ClickerPage,
        props: true
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.afterEach((to, from) => {
    console.log(to, from)
    if(to.name === from.name) return
    sidService.logAccess({ path: to.path })
})

export default router