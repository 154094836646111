import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import router from './router/router'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import VueGtag from "vue-gtag";
import './style/global.scss'

if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('/serviceWorker.js').then(function(registration) {
            console.log('ServiceWorker registration successful with scope: ', registration.scope);
        }, function(err) {
            console.log('ServiceWorker registration failed: ', err);
        });
    });
}


const vuetify = createVuetify({
    components,
    directives,
    icons:  {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    },
    theme: {
        colors: {
            primary: '#FF7043',
        }
    }
})

// Create a new store instance.
const store = createStore({
    state () {
        return {
            isLoading: false
        }
    },
    mutations: {
        SET_LOADING(state, payload) {
            state.isLoading = payload;
        }
    },
    actions: {
        setLoading({ commit }, isLoading) {
            commit('SET_LOADING', isLoading);
        }
    }
});

createApp(App, {
    onIsLoading: (isLoading) => { 
        console.log('isLoading', isLoading)
    }
})
    .use(store)
    .use(vuetify)
    .use(router)
    .use(VueGtag, {
        config: { id: "G-ERWHLL630J" }
    })
    .mount('#app')