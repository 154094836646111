<template>
  <div class="enci-group-detail-container">
    <div class="title-box primary-box">
      <h1>{{ group.name?.get(language) ?? "" }} ({{breeds?.length ?? ""}})</h1>
      <h2>{{ group.description?.get(language) ?? "" }}</h2>
      <v-select
          :items="sections"
          :label="getTranslation(language, 'section')"
          v-model="selectedSection"
          class="section-select"
          variant="solo"
          :menu-props="{maxWidth: 350}"
          hide-details
          @update:modelValue="filterBreed"
          rounded
      />
    </div>
<!--    <v-table density="compact">-->
<!--      <thead>-->
<!--      <tr>-->
<!--        <th>{{getTranslation(language, "image")}}</th>-->
<!--        <th>{{getTranslation(language, "section")}}</th>-->
<!--        <th>{{getTranslation(language, "name")}}</th>-->
<!--      </tr>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--      <tr v-for="breed in breeds" :key="breed.id" class="breed-row">-->
<!--        <td><img :src="breed.imageUrl" :alt="breed.name.get(language)" class="breed-image-td"></td>-->
<!--        <td>{{ breed.enciSection?.get(language) ?? "" }}</td>-->
<!--        <td>{{ breed.name.get(language) }}</td>-->
<!--      </tr>-->
<!--      </tbody>-->
<!--    </v-table>-->
<!--    <v-data-table-->
<!--        :headers="headers"-->
<!--        :items="breeds"-->
<!--        :items-per-page="5"-->
<!--        class="elevation-1">-->
<!--    </v-data-table>-->
    <v-card class="list-card" density="comfortable" v-if="!$vuetify.display.mdAndUp">
      <v-list>
        <v-list-item
            v-for="breed in filteredBreed"
            :key="breed.id"
            class="list-item"
            @click="navigateToBreedDetail(breed.id)"
        >
          <template v-slot:append>
              <img :src="breed.imageUrl" :alt="breed.name" width="50">
          </template>
          <v-list-item-title>{{ breed.name}}</v-list-item-title>
          <v-list-item-subtitle>{{ breed.enciSection}}</v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-card>
    <div v-else class="card-deck">
      <v-card @click="navigateToBreedDetail(breed.id)" class="card" v-for="breed in filteredBreed" :key="breed.id">
        <v-img :src="breed.imageUrl" :alt="breed.name" height="200px"></v-img>
        <div class="card-description">
          <v-card-title>{{ breed.name }}</v-card-title>
          <v-card-subtitle>{{ breed.enciSection }}</v-card-subtitle>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import apiService from "@/services/ApiService";
import {getTranslation} from "@/services/stringTableService";

export default {
  methods: {
    getTranslation,
    navigateToBreedDetail(id) {
      this.$router.push({ name: "BreedDetail", params: { id } });
    },
    async fetchGroup() {
      const groupId = this.$route.params.id;
      try {
        this.$store.dispatch('setLoading', true);
        const group = await apiService.getEnciGroup(groupId);
        this.group = group;
        const breeds = group.breeds.map(x => ({
          id: x.id,
          imageUrl: x.imageUrl,
          name: x.name?.get(this.language) ?? "",
          enciSection: x.enciSection?.get(this.language) ?? ""
        }));
        this.breeds = breeds;
        this.filteredBreed = breeds;
        this.selectedSection = "";
        
        this.sections = ["", ...breeds]
            .map(x => x.enciSection ?? "")
            .filter((value, index, self) => self.indexOf(value) === index)
            .map(x => {
              const title = x == "" 
                  ? ""
                  : `(${breeds.filter(b => b.enciSection === x).length}) ${x}`;
              return {
                title,
                value: x
              }
            })
        ;

        this.updateUrlWithSlug(group);
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch('setLoading', false);
      }
    },
    updateUrlWithSlug(data) {
      this.$router.replace({
        name: 'EnciGroupDetail',
        params: { id: data.id, slug: `ENCI-${data.externalId}` },

      });
    },
    filterBreed() {
      if (this.selectedSection === "") {
        this.filteredBreed = this.breeds;
      } else {
        this.filteredBreed = this.breeds.filter(x => x.enciSection === this.selectedSection);
      }
    }
  },
  watch: {
    $route(to, from) {
      if(to.params.id !== from.params.id)
        this.fetchGroup();
    }
  },
  data() {
    return {
      group: {},
      breeds: [],
      filteredBreed: [],
      sections: [],
      selectedSection: "",
      headers: [
        { text: getTranslation("IT", "image"), value: "imageUrl" },
        { text: getTranslation("IT", "section"), value: "enciSection" },
        { text: getTranslation("IT", "name"), value: "name" }
      ],
      language: "IT"
    };
  },
  async created() {
    this.fetchGroup();
  }
};
</script>

<style scoped lang="scss">
@import "../style/_variables.scss";

.enci-group-detail-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  h1, h2 {
    font-family: $header-font;
    font-weight: bolder;
  }
  .section-select {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 350px;
  }
  .list-card {
    margin-top: 10px;
    overflow-y: auto;
    max-height: 70vh;
  }
  .list-item {
    text-align: left;
    max-width: 90vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    :hover {
      color: $primary-color;
    }
    .v-list-item-title, .v-list-item-subtitle {
      text-overflow: unset !important;
      white-space: normal !important;
    }
  }
  .card-deck {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
    padding: 10px;
    max-height: 70vh;
    overflow-y: auto;
    .card {
      width: 250px;
      margin: 5px;
      :hover {
        color: $primary-color;
      }
      .card-description {
        background: $primary-gradient;
        height: 100%;
        .v-card-title, .v-card-subtitle {
          color: $primary-contrast-color;
          text-overflow: unset;
          white-space: normal;
        }
      }
    }
  }
}
</style>