<template>
  <v-autocomplete
      ref="inputField"
      class="search-all-autocomplete"
      v-model="search"
      :items="items"
      hide-details
      variant="solo"
      :label="label"
      clearable
      @update:modelValue="handleSelect"
      :menu-props="{maxWidth: '300px'}"
      rounded
  >
    <template v-slot:item="{ props, item }">
      <v-list-item
          class="list-item"
          v-bind="props"
      >
        <template v-slot:subtitle>
          <img :src="getIconName(item.value)" width="15" :alt="item.value">
          {{ getTranslation(language, getItemTypeName(item)).toUpperCase() }}
        </template>
        <template v-slot:title>
          {{ item.title.split(' - ')[1] }}
        </template>
        
      </v-list-item>
    </template>
<!--    <template v-slot:item="{ item }">-->
<!--      <div class="d-flex list-item" @click="handleClick(item)">-->
<!--        <v-icon class="list-item__icon">{{getIconName(item.value.split('-')[0])}}</v-icon>-->
<!--        <span class="list-item__text">{{ item.title }}</span>-->
<!--      </div>-->
<!--    </template>-->
  </v-autocomplete>
</template>
<script>
import {getTranslation} from "../services/stringTableService";

export default {
  name: 'SearchAllAutocomplete',
  props: ['label', 'variant'],
  data() {
    return {
      search: null,
      items: [],
      language: "IT"
    }
  },
  created() {
    this.fetchItems();
  },
  watch: {
    $route() {
      this.search = null;
    }
  },
  methods: {
    getTranslation,
    async fetchItems() {
      const response = await fetch('/cache/fastSearch.json');
      if (response.ok) {
        this.items = await response.json();
      } else {
        console.error('Error:', response.status);
      }
    },
    getItemTypeName(item) {
      const type = item.value.split('-')[0];
      if (!type) return;
      if(type === "breed")
        return 'breed';
      if(type === "enci")
        return item.title.split(' - ')[0];
      if(type === "motivation_group")
        return 'motivation_group';
    },
    getIconName(item) {
      const type = item.split('-')[0];
      if (!type) return;
      
      if(type === "breed")
        return '/img/paw-print-icon.svg';
      if(type === "enci")
        return '/img/dog-icon.svg';
      if(type === "motivation_group")
        return '/img/light-bulb-icon.svg';
      
      return '/img/dog-icon.svg';
    },
    handleSelect(item) {
      if (!item) return;
      const type = item.split('-')[0];
      const id = item.split('-')[1];
      if(type === 'breed') {
        this.$router.push({ name: 'BreedDetail', params: { id }});
      } else if(type === 'enci') {
        this.$router.push({ name: 'EnciGroupDetail', params: { id }});
      } else if(type === 'motivation_group') {
        this.$router.push({ name: 'MotivationalGroupDetail', params: { id }});
      }
    },  
    focusInput() {
      this.$refs.inputField.focus();
    }
    // getIconName(type) {
    //   switch (type) {
    //     case 'breed':
    //       return 'mdi-dog';
    //     case 'enci':
    //       return 'mdi-domain';
    //   }
    // },
    // handleClick(item) {
    //   console.log(item)
    //   this.search = item.value;
    // }
  }
}
</script>
<style scoped lang="scss">
@import '../style/_variables.scss';
.list-item {
  font-family: $font-base;
  padding: 0;
  .v-list-item__title {
    font-size: 12px;
    font-weight: 300;
  }
  .v-list-item__subtitle {
    font-size: 12px;
  }

  &:nth-child(odd) {
    background-color: rgba($primary-color, 0.2);
  }
}
.v-overlay-container {
  width: 250px !important;
}
</style>
