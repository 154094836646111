import it from '../assets/lang/it.json';

const languages = {it}

export function getStringTable(lang) {
    return languages[lang.toLowerCase()] ?? {};
}

export function getTranslation(lang, key) {
    return getStringTable(lang.toLowerCase())[key] ?? key;
}