<template>
  <div class="motivation-list-item">
    {{}}
    <v-dialog
        transition="dialog-bottom-transition"
        width="auto"
    >
      <template v-slot:activator="{ props: activatorProps }">
        <v-btn
            v-bind="activatorProps"
            :text="motivation.name.get(language)"
            block
        ></v-btn>
      </template>

      <template v-slot:default="{ isActive }">
        <v-card>
          <v-toolbar :title="motivation.name.get(language)"  class="card-toolbar"/>

          <v-card-text class="text-h6 pa-5">
            {{motivation.description.get(language)}}
          </v-card-text>

          <v-card-actions class="justify-end" v-if="false">
            <v-btn
                text="Close"
                @click="isActive.value = false"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import Motivation from "@/entities/Motivation";

export default {
  name: "MotivationItem",
  props: {
    motivation: {
      type: Motivation,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
  }
};
</script>
<style scoped lang="scss">
@import "../style/_variables.scss";

.motivation-list-item {
  padding-bottom: 5px;
  min-width: 130px;
}
.card-toolbar {
  background-color: $primary-color;
  color: $primary-contrast-color;
  text-align: center;
}
</style>
